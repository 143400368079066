import React from "react";
import ReactDOM from "react-dom/client";
import LandingApp from "./LandingApp";
import { NotificationProvider } from "../hooks/useNotification";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <NotificationProvider>
      <LandingApp />
    </NotificationProvider>
  </>
);
