import React, { useState, useContext, createContext } from "react";
import { NotifyProps, NotifyTypeType } from "../components/Notification";
import { getNextId } from "../redaction/utils/misc";

export type NotificationContextType = {
  notifications: NotifyProps[];
  addNotification: (text: string, type: NotifyTypeType) => void;
};

const NotificationContext = createContext<NotificationContextType>({
  notifications: [],
  addNotification: () => null,
});

export type NotificationProviderProps = {
  children: React.ReactElement;
};
export function NotificationProvider({ children }: NotificationProviderProps) {
  const [notifications, setNotifications] = useState<NotifyProps[]>([]);

  function addNotification(text: string, type: NotifyTypeType) {
    const id = getNextId();
    setNotifications([...notifications, { text: text, type: type, id: id }]);
    return id;
  }

  return (
    <NotificationContext.Provider value={{ notifications, addNotification }}>
      {children}
    </NotificationContext.Provider>
  );
}

export default function useNotification() {
  return useContext(NotificationContext);
}
