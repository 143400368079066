import React from "react";
import Button from "../../Button";

const ConcludeSummary = () => {
  return (
    <div className="landing__conclude-summary box--1by3">
      <div className="container landing__conclude__container">
        <div className="font-white-600 font-size-1p5 text-align-center">
          Intelligent real time document processing with speed, AI,
          <br /> Machine Learning, and state of the art security
        </div>
        <Button
          text="Sign-up"
          className="button--font-full font-weight-600 margin-center margin-top-2"
          clickHandler={() => (window.location.href = "/create_account/")}
        />
      </div>
    </div>
  );
};

export default ConcludeSummary;
