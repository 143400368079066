import React from "react";

import IntroSummary from "../components/home/IntroSummary";
import AboutUs from "../components/home/AboutUs";
import Pricing from "../components/home/Pricing";
import ConcludeSummary from "../components/home/ConcludeSummary";
import IntroRedaction from "../components/home/IntroRedaction";
import LandingFooter from "../components/home/LandingFooter";

import "../../styles/modules/home/landingApp.scss";

export default function LandingApp() {
  return (
    <>
      <IntroSummary />
      <IntroRedaction />
      {/* <AboutUs /> */}
      {/* <Pricing boxClass="box--body box--body--grey" /> */}
      <ConcludeSummary />
      <LandingFooter />
    </>
  );
}
