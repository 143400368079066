import React from "react";
import Button from "../Button";
import LexaparIcon from "../../../icons/lexapar-icon.svg";

const ICONPATH = "../../../../static/icons/";

export default function IntroSummary() {
  return (
    <div className="landing__intro-summary box--1by3" id="intro">
      <div className="container">
        <div className="landing__intro-summary__text">
          <div className="font-white-600 font-size-1p5">
            Smart Contract <br /> Analytics and Drafting
          </div>
          {/*          <span className="landing__intro-summary__keyword font-white-600 font-size-1p5">
            speed
          </span>*/}
          <img
            src={ICONPATH + "landing-text.gif"}
            className="landing__intro-summary__text-gif"
          />
          <Button
            text="Coming Soon"
            className="button--font-full display-block font-weight-600 no-decoration"
            clickHandler={() => null}
            // clickHandler={() => {
            //   window.location.hash = "#a"; // temporarily to allow repeat clicks in chrome
            //   window.location.hash = "#pricing";
            // }}
          />
        </div>
        <div className="landing__cta-box">
          <div className="font-weight-600 font-size-1p5">
            Power of Analytics
          </div>
          <div className="font-weight-400 margin-top-0p5">Coming Soon</div>
          <Button
            text="Sign-up"
            className="button--primary button--font-full font-weight-600 margin-center margin-top-1"
            clickHandler={() => (window.location.href = "/create_account/")}
          />
        </div>
      </div>
      <LexaparIcon className="landing__intro-summary__lexapar-icon" />
    </div>
  );
}
