import React from "react";

const IMAGEPATH = "../../../../static/images/";

export default function LandingFooter() {
  return (
    <>
      <div className="landing__footer">
        <div className="container">
          <div className="landing__footer__block">
            {/* Logo */}
            <img
              className="landing__footer__logo"
              src={IMAGEPATH + "home/Logo.png"}
            />
          </div>
          <div className="landing__footer__block">
            <p className="font-black-600">Redaction</p>
            <p>
              <a
                className="text-link-grey-72"
                href="/create_account"
                target="blank_"
              >
                Sign-up
              </a>
            </p>
          </div>
          <div className="landing__footer__block">
            {/* policies */}
            <p className="font-black-600">Policies</p>
            <p>
              <a
                className="text-link-grey-72"
                href="/privacy_policy"
                target="blank_"
              >
                Privacy Policy
              </a>
            </p>
            <p>
              <a
                className="text-link-grey-72"
                href="/terms_of_use"
                target="blank_"
              >
                Terms of Use
              </a>
            </p>
            <p>
              <a
                className="text-link-grey-72"
                href="/cancellation_and_refund"
                target="blank_"
              >
                Cancellation and Refund
              </a>
            </p>
          </div>
          <div className="landing__footer__block">
            {/* about */}
            <p className="font-black-600">About</p>
            <p>
              <a className="text-link-grey-72" href="#intro">
                Features
              </a>
            </p>
            <p>
              <a className="text-link-grey-72" href="#pricing">
                Pricing
              </a>
            </p>
          </div>
          <div className="landing__footer__block">
            {/* contact */}
            <p className="font-black-600">Contact</p>
            <p>
              <a
                className="text-link-grey-72"
                href="mailto:contact@lexapar.com"
              >
                Email us
              </a>
            </p>
          </div>
        </div>
        <footer className="text-align-center text-color-grey-71 margin-top-2">
          Copyright &copy; 2024 Lexapar Analytics Private Limited | All rights
          reserved
          {/* <a href="/privacy_policy" target="blank_">
        Privacy Policy
      </a>
      •
      <a href="/terms_of_use" target="blank_">
        Terms of Use
      </a> */}
        </footer>
      </div>
    </>
  );
}
