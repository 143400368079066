import { v4 as uuid4 } from "uuid";
import { IHighlight } from "../../lib/react-pdf-highlighter/src/types";
import { DocumentDataType } from "../../types";

export function formatDate(date: string | Date) {
  date = new Date(date);
  return date.toLocaleString("en-US", {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  });
}

export function getCookie(name: string) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export function getNextId() {
  return uuid4();
}

export function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

export function cleanText(string: string) {
  return string.replace(/[^a-zA-Z0-9 ]/g, "");
}

export function getHeaders() {
  const headers = new Headers();
  headers.set("X-CSRFToken", getCookie("csrftoken") || "");
  headers.set("Content-Type", "application/json");
  return headers;
}

export function fetchCreateLabel(uuid: string, name: string) {
  const headers = getHeaders();

  return fetch(`/redaction/api/${uuid}/label`, {
    method: "POST",
    headers: headers,
    body: JSON.stringify({
      name: name,
    }),
  });
}

export function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function fetchDownloadFile(
  url: string,
  onSuccess: () => void,
  onPermissionDenied: (status: string) => void,
  onError: (e: any) => void,
  onFinally: () => void
) {
  let filename: string;
  fetch(url)
    .then((response) => {
      if (response.status === 403) {
        onPermissionDenied(response.statusText);
        throw Error(response.statusText);
      } else if (!response.ok) {
        throw Error(response.statusText);
      }

      const header = response.headers.get("Content-Disposition") || "";
      const parts = header.split(";");
      filename = parts[1].split("=")[1].replaceAll('"', "");

      return response.blob();
    })
    .then((blob) => {
      const objectBlob = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = objectBlob;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      a.remove();
    })
    .then(() => onSuccess?.())
    // .then(blobUrl => {
    //     window.open(blobUrl, '_blank');
    //     URL.revokeObjectURL(blobUrl);
    // })
    .catch((e) => {
      onError?.(e);
    })
    .finally(() => onFinally?.());
}

export function isFirstTimeUser() {
  // Uncomment the following to activate check for first time.
  // return !Boolean(localStorage.getItem('visited'))
  return true;
}

export function setUserVisited() {
  localStorage.setItem("visited", "true");
}

// updates the document available count in the nav panel
export function updateDocumentAvailable() {
  const element = document.getElementById("document_available");
  if (element) {
    fetch(`/subscription/api/document_available/`)
      .then((response) => response.json())
      .then((response) => {
        element.textContent = response;
      })
      .catch((e) =>
        console.log(`error while updating document available ${e}`)
      );
  }
}

// Run the Fetch Promise for OCR Run and Save API and returns the response
export function fetchOcrRunAndSave(uuid: string) {
  return fetch(`/document/api/document/${uuid}/ocr_run_and_save/`).then(
    (response) => response.json()
  );
}

// Returns the fetch for deleting document. Need to pass onSuccess, onFailure, onError
// and onFinally callbacks.
export function fetchDeleteDocument(
  uuid: string,
  onSuccess: () => void,
  onFailure: () => void,
  onError: () => void,
  onFinally: () => void
) {
  const headers = getHeaders();

  return fetch(`/document/api/document/${uuid}/`, {
    method: "DELETE",
    headers: headers,
  })
    .then((response) => {
      response.status === 204 ? onSuccess() : onFailure();
    })
    .catch(onError)
    .finally(onFinally);
}

// Call the API with POST request to save the highlights. Returns the fetch
// promise
export function fetchHighlightsSave(uuid: string, highlights: IHighlight[]) {
  return fetch(`/redaction/api/highlights/save/${uuid}/`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      data_json: highlights,
    }),
  });
}

export function fetchDocumentUpload(
  file: File,
  fileName: string,
  isOcr: boolean
) {
  const formData = new FormData();
  formData.append("file", file, fileName);

  if (isOcr) {
    formData.append("ocr", "true");
  }

  const headers = new Headers();
  headers.set("X-CSRFToken", getCookie("csrftoken") || "");
  return fetch(`/document/api/document/upload/`, {
    method: "POST",
    headers: headers,
    body: formData,
  });
}

export function fetchDocumentProperties(uuid: string) {
  return fetch(`/document/api/document/${uuid}/properties/`).then((response) =>
    response.json()
  );
}

export function fetchModelFeedback(
  pred_uuid: string,
  rating: number,
  data: any
) {
  return fetch(`/api/model_feedback/`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      pred_uuid: pred_uuid,
      data: data,
      rating: rating,
    }),
  });
}

export function fetchInformationExtraction(uuid: string) {
  return fetch(`/analytics/api/information_extraction/${uuid}/`).then(
    (response) => response.json()
  );
}

export function fetchRiskClause(uuid: string) {
  return fetch(`/analytics/api/risk_clause/${uuid}/`).then((response) =>
    response.json()
  );
}

export function fetchQuestionAnswering(
  uuid: string,
  query: string,
  history: string
) {
  console.log("DEBUG running question answering api");
  return fetch(`/analytics/api/question_answering/${uuid}/`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      query: query,
      history: history,
      stream: false,
    }),
  }).then((response) => {
    console.log("DEBUG question answering response", response);
    if (response.ok) {
      return response.json();
    } else {
      console.log("fetchQuestionAnswering Error", response.statusText);
    }
  });
}

export async function consumeStreamFetch(
  url: string,
  method: string,
  body: any,
  callback: (t: (s: string) => string) => void,
  onDone?: () => void
) {
  // Make a request for a user with a given ID
  return fetch(url, {
    method: method,
    headers: getHeaders(),
    body: body
      ? JSON.stringify({
          ...body,
        })
      : undefined,
  }).then((response) => {
    if (response.ok && response.body) {
      const reader = response.body
        .pipeThrough(new TextDecoderStream())
        .getReader();

      const readStream: () => Promise<
        ReadableStreamReadResult<string> | undefined
      > = () =>
        reader.read().then(({ value, done }) => {
          callback((x: string) => x + (value || ""));
          if (done) {
            reader.cancel();
            onDone?.();
            return;
          }

          // parse the data
          const data = /{.*}/.exec(value);

          if (!data || !data[0]) {
            return readStream();
          }

          // do something if success
          // and cancel the stream
          reader.cancel().catch(() => null);

          return readStream();
        });
      return readStream();
    } else {
      return Promise.reject(response);
    }
  });
}

// export function fetchClauseRiskMarketReason(
//   section: string,
//   uuid: string,
//   perspective: string
// ) {
//   return fetch(`/analytics/api/clause_risk_market_reason/${uuid}/`, {
//     method: "POST",
//     headers: getHeaders(),
//     body: JSON.stringify({
//       section: section,
//       perspective: perspective,
//     }),
//   }).then((response) => {
//     if (response.ok) {
//       return response.json();
//     } else {
//       console.log("fetchClauseRiskMarketReason Error", response.statusText);
//     }
//   });
// }

export function fetchClauseRiskMarketSuggestion(
  section: string,
  uuid: string,
  perspective: string
) {
  return fetch(`/analytics/api/clause_risk_market_suggestion/${uuid}/`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      section: section,
      perspective: perspective,
    }),
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      console.log("fetchClauseRiskMarketSuggestion Error", response.statusText);
    }
  });
}

// export function fetchDocumentComponentStructure(
//   uuid: string,
//   perspective: string
// ) {
//   return fetch(
//     `/analytics/api/document_component_structure/${perspective}/${uuid}/`
//   ).then((response) => response.json());
// }

export function fetchDocuments(): Promise<DocumentDataType[]> {
  return fetch(`/document/api/document/`).then((response) => response.json());
}

// export function fetchDocumentCompare(
//   uuid1: string,
//   uuid2: string,
//   perspective: string
// ) {
//   return fetch(
//     `/analytics/api/document_compare/${perspective}/${uuid1}/${uuid2}`
//   ).then((response) => response.json());
// }

export function fetchRiskMarket(uuid: string, perspective: string) {
  return fetch(
    `/analytics/api/document_risk_market/${perspective}/${uuid}/`
  ).then((response) => response.json());
}

export function fetchRiskStandard(
  uuid: string,
  perspective: string,
  standardUuid: string
) {
  return fetch(
    `/analytics/api/document_risk_standard/${perspective}/${uuid}/${standardUuid}/`
  ).then((response) => response.json());
}

export function fetchRiskPlaybook(
  uuid: string,
  perspective: string,
  playbook_uuids: string[],
  min_frequency?: number
) {
  return fetch(
    `/analytics/api/document_risk_playbook/${perspective}/${uuid}/`,
    {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({
        playbook_uuids: playbook_uuids,
        min_frequency: min_frequency,
      }),
    }
  ).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      console.log("fetchRiskPlaybook Error", response.statusText);
    }
  });
}
export function fetchRiskPlaybookFrequencies(
  uuid: string,
  perspective: string,
  playbook_uuids: string[]
) {
  return fetch(
    `/analytics/api/document_risk_playbook_frequencies/${perspective}/${uuid}/`,
    {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({
        playbook_uuids: playbook_uuids,
      }),
    }
  ).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      console.log("fetchRiskPlaybook Error", response.statusText);
    }
  });
}

export function fetchRiskPlaybookGuideExport(
  uuid: string,
  perspective: string,
  playbook_uuids: string[],
  minFrequency: number
) {
  let filename: string;
  return fetch(
    `/analytics/api/document_risk_playbook_guide_export/${perspective}/${uuid}/`,
    {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({
        playbook_uuids: playbook_uuids,
        min_frequency: minFrequency,
      }),
    }
  )
    .then((response) => {
      if (response.ok) {
        const header = response.headers.get("Content-Disposition") || "";
        const parts = header.split(";");
        filename = parts[1].split("=")[1].replaceAll('"', "");

        return response.blob();
      } else {
        console.log("fetchRiskPlaybook Error", response.statusText);
      }
    })
    .then((blob) => {
      const objectBlob = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = objectBlob;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      a.remove();
    });
}
